import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de 4 a 8 cm de diàmetre convex a pla de color groguenc a groc verdós, amb esquames petites, molt nombroses de color marró grisenc que es troben sobretot al centre. Les làmines són lliures i escotades i es fan notar pel seu color groc llimona viu. El peu és cilíndric, engruixut al centre, massís i amb fibril·les de color marró terrós. Les espores són blanquinoses en massa, el·lipsoidals, de 6-8 x 3-4 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      